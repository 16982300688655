import { Flex, Box } from '@chakra-ui/react'

import { useSelectiveProcess } from '../hook/selectiveProcess'

interface DateTimeDisplayProps {
  isDanger?: boolean
  type: string
  value: number
}

export function DateTimeDisplay({
  isDanger = false,
  type,
  value,
}: DateTimeDisplayProps) {
  const { selectiveProcess } = useSelectiveProcess()

  return (
    <Flex
      color={
        isDanger
          ? 'red.600'
          : selectiveProcess.color_base === '#FFF'
          ? '#002147'
          : selectiveProcess.color_base
      }
      flexDirection="column"
      align="center"
      justify="center"
    >
      <Box
        fontSize={['4xl', '4xl', '4xl', '8xl']}
        lineHeight="none"
        fontWeight="bold"
      >
        {value.toString().padStart(2, '0')}
      </Box>
      <Box
        fontSize={['xl', 'xl', 'xl', '4xl']}
        fontWeight="bold"
        lineHeight="none"
      >
        {type.toString().padStart(2, '0')}
      </Box>
    </Flex>
  )
}
