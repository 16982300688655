import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import api from '../services/api'

interface SignInCredentials {
  email: string
  password: string
  selective_process_id: number
}

interface Candidate {
  id: string
  name: string
  avatar: string
  status: string
  transaction_type: string
  registration_ticket: string
  summons_card: string
}

interface AuthStateCandidate {
  token: string
  candidate: Candidate
}

interface AuthContextData {
  candidate: Candidate
  signInCandidate(credentials: SignInCredentials): Promise<void>
  signOutCandidate(): void
  updateDataCandidate(data: AuthStateCandidate): void
}

interface ResponseAuthCandidate {
  token: string
  candidate: Candidate
}

interface AuthProviderProps {
  children: ReactNode
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

function AuthProvider({ children }: AuthProviderProps) {
  const [dataCandidate, setDataCandidate] = useState<AuthStateCandidate>(() => {
    const token = sessionStorage.getItem('@Candidate:token')
    const candidate = sessionStorage.getItem('@Candidate:candidate')

    if (token && candidate) {
      api.defaults.headers.authorization = `Bearer ${token}`
      return { token, candidate: JSON.parse(candidate) }
    }

    return {} as AuthStateCandidate
  })

  const signInCandidate = useCallback(
    async ({ email, password, selective_process_id }: SignInCredentials) => {
      const response = await api.post<ResponseAuthCandidate>(
        'candidates/sessions',
        {
          email,
          password,
          selective_process_id,
        },
      )

      const { token, candidate } = response.data

      sessionStorage.setItem('@Candidate:token', token)
      sessionStorage.setItem('@Candidate:candidate', JSON.stringify(candidate))

      api.defaults.headers.authorization = `Bearer ${token}`

      setDataCandidate({ token, candidate })
    },
    [],
  )

  const signOutCandidate = useCallback(() => {
    sessionStorage.removeItem('@Candidate:token')
    sessionStorage.removeItem('@Candidate:candidate')

    setDataCandidate({} as AuthStateCandidate)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        candidate: dataCandidate.candidate,
        signInCandidate,
        signOutCandidate,
        updateDataCandidate: setDataCandidate,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
