import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from './routes'
import AppProvider from './hook'

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider>
        <AppProvider>
          <Routes />
        </AppProvider>
      </ChakraProvider>
    </BrowserRouter>
  )
}

export default App
