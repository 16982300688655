import axios from 'axios'

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_URL_API_DEVELOPMENT
      : process.env.REACT_APP_URL_API_PRODUCTION,
})

export default api
