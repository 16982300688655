import { Navigate, Outlet } from 'react-router-dom'

import { useAuth } from '../../hook/auth'
import { useSelectiveProcess } from '../../hook/selectiveProcess'

const Route = () => {
  const { candidate } = useAuth()
  const { selectiveProcess } = useSelectiveProcess()

  return !!candidate === true ? (
    <Outlet />
  ) : (
    <Navigate to={`/${selectiveProcess.process_alias}/signin`} />
  )
}

export default Route
