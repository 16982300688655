import {
  Divider,
  Flex,
  Heading,
  Icon,
  Link as ChakraLink,
  Skeleton,
  useToast,
  Text,
} from '@chakra-ui/react'
import {
  ArrowLeft,
  ClipboardText,
  CreditCard,
  User,
  UserCircle,
} from '@phosphor-icons/react'
import { v4 as uuidv4 } from 'uuid'
import { initMercadoPago, Payment, StatusScreen } from '@mercadopago/sdk-react'
import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { useSelectiveProcess } from '../hook/selectiveProcess'
import api from '../services/api'

initMercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY!, {
  locale: 'pt-BR',
})

export interface IAdditionalInfoItems {
  unit_price: number
  quantity: number
  title: string
  description?: string
  picture_url?: string
}

export interface IAdditionalInfoShipments {
  receiver_address: {
    zip_code?: string
    state_name?: string
    city_name?: string
    street_name?: string
    street_number?: number
    apartment?: string
  }
}

export interface IAdditionalInfo {
  items?: IAdditionalInfoItems[]
  shipments?: IAdditionalInfoShipments
}

export type IFormDataAdditionalInfo = {
  additional_info?: IAdditionalInfo
}

export interface TicketFormData {
  transaction_amount: number
  payment_method_id: string
  payer: {
    email: string
    identification: {
      type: string
      number: string
    }
    first_name: string
    last_name: string
    address: {
      zip_code: string
      federal_unit: string
      city: string
      neighborhood: string
      street_name: string
      street_number: string
    }
    entity_type?: 'individual' | 'association'
  }
  transaction_details?: {
    financial_institution: string
  }
  metadata?: {
    payment_point?: string
    payment_mode?: string
  }
}

export interface ICardPaymentBrickPayer {
  email?: string
  identification?: {
    type: string
    number: string
  }
}

interface ISavedCardPayer {
  type: string
  id: string
}

export interface ICardPaymentFormData<ICardPaymentBrickPayer> {
  token: string
  issuer_id: string
  payment_method_id: string
  transaction_amount: number
  installments: number
  payer: ICardPaymentBrickPayer
  payment_method_option_id?: string
  processing_mode?: string
}

interface IPaymentFormData {
  selectedPaymentMethod:
    | 'atm'
    | 'ticket'
    | 'bank_transfer'
    | 'creditCard'
    | 'debitCard'
    | 'wallet_purchase'
    | 'onboarding_credits'
  formData: ICardPaymentFormData<ICardPaymentBrickPayer> &
    ICardPaymentFormData<ISavedCardPayer> &
    TicketFormData &
    IFormDataAdditionalInfo
}

interface ResponseCandidateData {
  name: string
  email: string
  phone: string
  cellPhone: string
  zip_code: string
  address: string
  number: string
  complement: string
  district: string
  city: string
  state: string
  created_at: string
}

interface PaymentDataResponse {
  paymentId: string
  paymentStatus: string
}

export default function RegisterPayment() {
  const { candidate_id } = useParams()

  const [candidate, setCandidate] = useState<ResponseCandidateData>()
  const [paymentData, setPaymentData] = useState<PaymentDataResponse | null>(
    null,
  )

  const { selectiveProcess } = useSelectiveProcess()

  useEffect(() => {
    api
      .get<ResponseCandidateData>(`candidates/basic/${candidate_id}`)
      .then((response) => {
        const { data } = response

        setCandidate(data)
      })
  }, [candidate_id])

  const toast = useToast()

  async function handleSubmitPayment({
    selectedPaymentMethod,
    formData,
  }: IPaymentFormData) {
    try {
      const dataUpdateCandidate = {
        selective_process_id: selectiveProcess.id,
        candidate_id,
        selectedPaymentMethod,
        formData,
      }

      const { data } = await api.put('candidates', dataUpdateCandidate)

      setPaymentData({
        paymentId: data.payment_id,
        paymentStatus: data.payment_status,
      })
    } catch (err) {
      setPaymentData(null)

      toast({
        title: 'Desculpe, mas ocorreu um erro ao processar o pagamento!',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })

      window.location.href = `/${selectiveProcess.process_alias}/register/payment/${candidate_id}`
    }
  }

  const preferenceId = uuidv4()

  if (!selectiveProcess || !candidate) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          flexDirection="column"
          px="4"
        >
          <Flex
            maxW={800}
            w="100%"
            align="center"
            justify="center"
            flexDirection="column"
            py="6"
            px="8"
            borderRadius={20}
          >
            <Skeleton height={500} width="100%" />
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      flexDirection="column"
      px="4"
      minH="90vh"
    >
      <Heading
        as="h2"
        color={
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }
        textAlign="center"
        my="10"
      >
        {selectiveProcess.process_name}
      </Heading>

      <Flex
        maxW={800}
        w="100%"
        align="center"
        justify="center"
        flexDirection="column"
        py="6"
        px="8"
        borderRadius={20}
        border={`3px solid ${
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }`}
      >
        <Flex
          w="100%"
          maxW={['100%', 600]}
          justify="center"
          align="center"
          gap="2"
          my="6"
        >
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon as={User} fontSize="40px" color="#FFF" weight="fill" />
          </Flex>

          <Divider
            flex="1"
            borderBottomWidth="4px"
            borderColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
          />
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon
              as={ClipboardText}
              fontSize="40px"
              color="#FFF"
              weight="fill"
            />
          </Flex>

          <Divider
            flex="1"
            borderBottomWidth="4px"
            borderColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
          />

          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon as={CreditCard} fontSize="40px" color="#FFF" weight="fill" />
          </Flex>
        </Flex>

        <Heading
          as="h3"
          color={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          textAlign="center"
          my="6"
        >
          Pagamento
        </Heading>

        <Text mb="8">
          <strong>ATENÇÃO!</strong>
          <br />
          As informações que constarão em seu boleto de pagamento são:
          <br />
          <strong>
            Editora e Educação Empreendedora Ltda. COVEST-MED
            <br />
            CNPJ: 07.821.193/0001-90
          </strong>
          <br />
          Para quitação por cartão de crédito, constará na fatura o título{' '}
          <strong>MP*Editora</strong>
        </Text>

        {!paymentData && (
          <Payment
            initialization={{
              amount: selectiveProcess.registration_fee,
              preferenceId,
              payer: {
                firstName: candidate.name.split(' ')[0],
                lastName: candidate.name.split(' ')[1],
                email: candidate.email,
                address: {
                  zipCode: candidate.zip_code,
                },
              },
            }}
            customization={{
              visual: {
                defaultPaymentOption: {
                  ticketForm: true,
                },
              },
              paymentMethods: {
                atm: 'all',
                ticket: ['bolbradesco'],
                mercadoPago: 'all',
                minInstallments: 1,
                maxInstallments: 10,
              },
            }}
            onSubmit={handleSubmitPayment}
          />
        )}

        {paymentData && (
          <>
            <StatusScreen
              initialization={{
                paymentId: paymentData.paymentId,
              }}
            />

            {paymentData.paymentStatus === 'pending' ||
            paymentData.paymentStatus === 'approved' ||
            paymentData.paymentStatus === 'in_process' ||
            paymentData.paymentStatus === 'authorized' ? (
              <ChakraLink
                as={NavLink}
                display="flex"
                justifyContent="center"
                alignItems="center"
                to={`/${selectiveProcess.process_alias}/signin`}
                borderRadius="12"
                gap="2"
                h="12"
                px="4"
                fontWeight="bold"
                bgColor={
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base
                }
                color="#FFF"
                border="2px solid transparent"
                _hover={{
                  borderColor:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                  bgColor: 'transparent',
                  color:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                }}
              >
                Portal do candidato
                <UserCircle size={22} />
              </ChakraLink>
            ) : (
              <ChakraLink
                href={`/${selectiveProcess.process_alias}/register/payment/${candidate_id}`}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                borderRadius="12"
                gap="2"
                h="12"
                px="3"
                mt="2"
                fontWeight="bold"
                fontSize="18"
                color={
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base
                }
              >
                <ArrowLeft size={20} />
                Volta
              </ChakraLink>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}
