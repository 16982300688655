import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Flex, Image, Heading, Button, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'

import { Input } from '../components/Form/Input'
import api from '../services/api'
import { useSelectiveProcess } from '../hook/selectiveProcess'

const resetPasswordFormSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: 'A senha precisa ter pelo menos 8 caracteres.' })
      .regex(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[$*&@#])(?!.*(.)\1)[A-Za-z\d$*&@#]{8,}$/,
        {
          message:
            'No mínimo 1 Letra Maiúscula, 1 Número no mínimo, 1 Símbolo ($*&@#) e não permitir sequência igual.',
        },
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não são iguais.',
    path: ['confirmPassword'],
  })

type ResetPasswordFormData = z.infer<typeof resetPasswordFormSchema>

export function ResetPassword() {
  const { token } = useParams()

  const { selectiveProcess } = useSelectiveProcess()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ResetPasswordFormData>({
    resolver: zodResolver(resetPasswordFormSchema),
  })

  const toast = useToast()
  const navigate = useNavigate()

  async function handleResetPassword(data: ResetPasswordFormData) {
    try {
      await api.post('candidates/reset-password', {
        token,
        password: data.password,
      })

      toast({
        title: 'Senha alterada com sucesso.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      navigate(`/${selectiveProcess.process_alias}/signin`)
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao tentar redefinir sua senha.',
        description:
          'Verifique se o link de redefinição de senha é válido e tente novamente. Se o problema persistir, entre em contato com o suporte pelo whastapp.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <Flex w="100%" justify="center" align="center" minH="90vh">
        <Flex
          as="form"
          onSubmit={handleSubmit(handleResetPassword)}
          maxW={550}
          w="100%"
          align="center"
          justify="center"
          flexDirection="column"
          py="6"
          px="8"
          mt="14"
          mx="4"
          borderRadius={20}
          border={`3px solid ${
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }`}
        >
          <Image
            src={selectiveProcess.url_logo.split('^')[0]}
            alt="Fatra"
            maxW={180}
            w="100%"
            bgColor={selectiveProcess.color_base}
            borderRadius="12"
          />
          <Heading
            as="h2"
            color={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            my="8"
          >
            Redefinir Senha
          </Heading>
          <Flex flexDirection="column" width="100%" gap="4">
            <Input
              label="Senha:"
              type="password"
              placeholder="Digite sua senha"
              {...register('password')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.password}
            />
            <Input
              label="Confirmação senha:"
              type="password"
              placeholder="Confirme sua senha"
              {...register('confirmPassword')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.confirmPassword}
            />
          </Flex>

          <Flex
            as={Button}
            type="submit"
            isLoading={isSubmitting}
            colorScheme="teal"
            variant="outline"
            spinnerPlacement="start"
            w="100%"
            h={55}
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            color="#FFF"
            border="2px solid transparent"
            _hover={{
              borderColor:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
              bgColor: 'transparent',
              color:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
            }}
            fontWeight="bold"
            fontSize="25"
            borderRadius="12"
            justify="center"
            align="center"
            gap="2"
            my="8"
          >
            Redefinir
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
