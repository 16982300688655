import { useEffect, useState } from 'react'
import {
  Flex,
  Image,
  Link as ChakraLink,
  Heading,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  List,
  ListItem,
  Skeleton,
  SkeletonText,
  ListIcon,
} from '@chakra-ui/react'
import {
  UserCircle,
  ArrowRight,
  FilePdf,
  FileText,
  FileImage,
} from '@phosphor-icons/react'
import dayjs from 'dayjs'
import { NavLink } from 'react-router-dom'

import api from '../services/api'
import { useSelectiveProcess } from '../hook/selectiveProcess'

import { WhatsAppButton } from '../components/WhatsAppButton'
import dateIsSameOrAfter from '../utils/dateIsSameOrAfter'
import dateIsSameOrBefore from '../utils/dateIsSameOrBefore'
import getMimeTypeFromExtension from '../utils/getMimeTypeFromExtension'
import { CountDown } from '../components/CountDown'

interface ResponseTimelineSelectiveProcess {
  id: number
  selective_processes_id: string
  description: string
  date_start: string
  date_end: string
  subscription_open: string
  url: string
}

interface ResponseDocumentSelectiveProcess {
  id: number
  selective_processes_id: string
  description: string
  url: string
}

export function Home() {
  const [timelineSelectiveProcess, setTimelineSelectiveProcess] = useState<
    ResponseTimelineSelectiveProcess[]
  >([])
  const [infoRegistration, setInfoRegistration] =
    useState<ResponseTimelineSelectiveProcess>()
  const [documentsSelectiveProcess, setDocumentsSelectiveProcess] = useState<
    ResponseDocumentSelectiveProcess[]
  >([])

  const { selectiveProcess } = useSelectiveProcess()

  useEffect(() => {
    api
      .get<ResponseTimelineSelectiveProcess[]>(
        `selectives-process/timeline/${selectiveProcess.id}`,
      )
      .then((response) => {
        setTimelineSelectiveProcess(response.data)

        const timeline = response.data

        const indexTimeline = timeline.findIndex(
          (timeline) => timeline.description === 'Inscrições',
        )
        const info = timeline[indexTimeline]

        setInfoRegistration(info)
      })

    api
      .get<ResponseTimelineSelectiveProcess[]>(
        `selectives-process/documents/not-restricted/${selectiveProcess.id}`,
      )
      .then((response) => {
        setDocumentsSelectiveProcess(response.data)
      })
  }, [selectiveProcess.id])

  if (
    !selectiveProcess ||
    !timelineSelectiveProcess ||
    !documentsSelectiveProcess
  ) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          h={[280, 280, 120]}
          bgColor="#FFF"
        >
          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            align="center"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            px="6"
          >
            <Skeleton height="80px" width={200} />

            <Flex gap="4" flexDirection={['column', 'column', 'row']}>
              <Skeleton height="55px" width={200} />

              <Skeleton height="55px" width={200} />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" w="100%" align="center">
          <Skeleton height="400px" width="100%" />

          <Flex
            maxW={1200}
            w="100%"
            justify="space-between"
            flexDirection={['column', 'column', 'row']}
            gap="8"
            p="4"
            my={['8', '12']}
          >
            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              border="2px solid #002147"
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>

            <Flex
              maxW={550}
              w="100%"
              justify="center"
              flexDirection="column"
              gap="4"
              borderRadius={12}
              border="2px solid #002147"
              p="8"
            >
              <Skeleton height="40px" />
              <SkeletonText
                mt="4"
                noOfLines={8}
                spacing="4"
                skeletonHeight="2"
              />
            </Flex>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex
        as="header"
        w="100%"
        justify="center"
        align="center"
        h={[280, 280, 100]}
        position="fixed"
        bgColor={selectiveProcess.color_header}
      >
        <Flex
          maxW={1250}
          w="100%"
          justify="space-between"
          align="center"
          flexDirection={['column', 'column', 'row']}
          gap="8"
          px="6"
        >
          <Image
            src={selectiveProcess.url_logo.split('^')[0]}
            alt="Fatra"
            maxW={180}
            w="100%"
          />
          <Flex gap="4" flexDirection={['column', 'column', 'row']}>
            {infoRegistration &&
              dateIsSameOrAfter(infoRegistration.date_start) &&
              dateIsSameOrBefore(infoRegistration.date_end) && (
                <ChakraLink
                  as={NavLink}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  to={`/${selectiveProcess.process_alias}/register/personal-data`}
                  borderRadius="12"
                  gap="2"
                  h="12"
                  px="3"
                  fontWeight="bold"
                  color="#fff"
                  bgColor={selectiveProcess.color_base}
                  border="2px solid transparent"
                  _hover={{
                    textDecoration: 'none',
                    color:
                      selectiveProcess.color_header === '#FFF'
                        ? selectiveProcess.color_base
                        : '#FFF',
                    borderColor:
                      selectiveProcess.color_header === '#FFF'
                        ? selectiveProcess.color_base
                        : '#FFF',
                    bgColor: 'transparent',
                  }}
                >
                  Quero me inscrever
                </ChakraLink>
              )}
            <ChakraLink
              as={NavLink}
              display="flex"
              justifyContent="center"
              alignItems="center"
              to={`/${selectiveProcess.process_alias}/signin`}
              borderRadius="12"
              gap="2"
              h="12"
              px="3"
              fontWeight="bold"
              color={
                selectiveProcess.color_header === '#FFF'
                  ? selectiveProcess.color_base
                  : '#FFF'
              }
              border={`2px solid ${
                selectiveProcess.color_header === '#FFF'
                  ? selectiveProcess.color_base
                  : '#FFF'
              }`}
              _hover={{
                textDecoration: 'none',
                borderColor: 'transparent',
                color: '#fff',
                bgColor: selectiveProcess.color_base,
              }}
            >
              Portal do candidato
              <UserCircle size={22} />
            </ChakraLink>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        as="main"
        flexDirection="column"
        w="100%"
        align="center"
        pt={[280, 280, 100]}
      >
        <WhatsAppButton
          href={`http://wa.me/${selectiveProcess.whatsapp_number}/?text=Ola`}
        />

        <Flex w="100%" justify="center">
          {infoRegistration &&
          dateIsSameOrAfter(infoRegistration.date_start) &&
          dateIsSameOrBefore(infoRegistration.date_end) ? (
            <ChakraLink
              as={NavLink}
              to={`/${selectiveProcess.process_alias}/register/personal-data`}
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Image
                src={selectiveProcess.url_banner.split('^')[0]}
                alt=""
                maxW={1920}
                w="100%"
              />
            </ChakraLink>
          ) : (
            <Image
              src={selectiveProcess.url_banner.split('^')[0]}
              alt=""
              maxW={1920}
              w="100%"
            />
          )}
        </Flex>

        {infoRegistration && dateIsSameOrBefore(infoRegistration.date_end) && (
          <Flex
            maxW={1250}
            w="100%"
            justify="center"
            align="center"
            flexDirection="column"
            gap="8"
            p="4"
            my="8"
          >
            <Heading
              as="h2"
              color={selectiveProcess.color_base}
              textAlign="center"
              fontSize="7xl"
            >
              Faltam
            </Heading>

            <CountDown dateEnd={infoRegistration.date_end} />

            <Text textAlign="center" fontSize="xl" fontWeight="bold">
              Para garantir a sua inscrição e participação no processo seletivo.
              <br />
              Não deixe passar essa oportunidade!
            </Text>
          </Flex>
        )}

        <Flex
          maxW={1250}
          w="100%"
          justify="space-between"
          flexDirection={['column', 'column', 'row']}
          gap="8"
          p="4"
          my={['8', '12']}
        >
          <Flex
            maxW={600}
            w="100%"
            justify="space-between"
            flexDirection="column"
            gap="4"
            borderRadius={12}
            border={`2px solid ${selectiveProcess.color_base}`}
            p="8"
          >
            <Heading as="h2" color={selectiveProcess.color_base}>
              Sobre a instituição
            </Heading>
            <Text
              dangerouslySetInnerHTML={{
                __html: selectiveProcess.description_institutional,
              }}
            ></Text>
            <Flex
              as={ChakraLink}
              justify="flex-end"
              align="center"
              borderRadius="12"
              gap="2"
              h="12"
              px="3"
              fontWeight="bold"
              color={selectiveProcess.color_base}
              href={selectiveProcess.url_institutional}
              target="_blank"
              _hover={{
                filter: 'brightness(0.9)',
              }}
            >
              Saiba Mais
              <ArrowRight size={22} />
            </Flex>
          </Flex>
          <Flex
            maxW={600}
            w="100%"
            justify="space-between"
            flexDirection="column"
            gap="4"
            borderRadius={12}
            border={`2px solid ${selectiveProcess.color_base}`}
            p="8"
          >
            <Heading as="h2" color={selectiveProcess.color_base}>
              Sobre o curso
            </Heading>
            <Text
              dangerouslySetInnerHTML={{
                __html: selectiveProcess.description_process,
              }}
            ></Text>
            <Flex
              as={ChakraLink}
              justify="flex-end"
              align="center"
              borderRadius="12"
              gap="2"
              h="12"
              px="3"
              fontWeight="bold"
              color={selectiveProcess.color_base}
              href={selectiveProcess.url_process}
              target="_blank"
              _hover={{
                filter: 'brightness(0.9)',
              }}
            >
              Saiba Mais
              <ArrowRight size={22} />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          maxW={1250}
          w="100%"
          justify="space-between"
          p="4"
          my={['8', '12']}
        >
          <Tabs variant="enclosed" w="100%">
            <TabList>
              <Tab
                _selected={{
                  color: 'white',
                  bg:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                }}
              >
                Cronograma
              </Tab>
              <Tab
                _selected={{
                  color: 'white',
                  bg:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                }}
              >
                Informações gerais
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <List spacing={3}>
                  {timelineSelectiveProcess.map((timeline) => (
                    <ListItem key={timeline.id}>
                      {dayjs(timeline.date_start).isSame(timeline.date_end)
                        ? `${timeline.description} - ${dayjs(
                            timeline.date_start,
                          ).format('DD/MM/YYYY')}`
                        : `${timeline.description} - ${dayjs(
                            timeline.date_start,
                          ).format('DD/MM/YYYY')} até ${dayjs(
                            timeline.date_end,
                          ).format('DD/MM/YYYY')}`}
                      {dateIsSameOrAfter(timeline.date_start) &&
                      dateIsSameOrBefore(timeline.date_end)
                        ? ' - Em andamento'
                        : dayjs(timeline.date_end).isBefore(new Date())
                        ? ' - Finalizado'
                        : ''}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List spacing={3}>
                  {documentsSelectiveProcess.map((document) => (
                    <ListItem key={document.id}>
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'image' && (
                        <ListIcon
                          as={FileImage}
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'document' && (
                        <ListIcon
                          as={FileText}
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'pdf' && (
                        <ListIcon
                          as={FilePdf}
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                        />
                      )}

                      {document.url ? (
                        <ChakraLink
                          h="12"
                          px="3"
                          fontWeight="bold"
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                          href={document.url.split('^')[0]}
                          target="_blank"
                          _hover={{
                            filter: 'brightness(0.9)',
                          }}
                        >
                          {document.description}
                        </ChakraLink>
                      ) : (
                        document.description
                      )}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </>
  )
}
