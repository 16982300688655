export default function dateIsSameOrAfter(date_start: string): boolean {
  // Converter a data do banco (string) em um objeto Date
  const date_compare: Date = new Date(date_start)

  // Obter a data atual
  const currentDate: Date = new Date()

  // Comparar as datas
  if (currentDate >= date_compare) {
    return true
  } else {
    return false
  }
}
