import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import api from '../services/api'

interface SelectiveProcess {
  id: number
  company_id?: string
  process_name: string
  process_alias: string
  status: string
  process_date_start: Date
  process_date_end: Date
  url_logo: string
  url_banner: string
  url_banner_mobile: string
  description_institutional: string
  url_institutional: string
  description_process: string
  url_process: string
  url_form: string
  whatsapp_number: string
  color_base: string
  color_header: string
  url_instagram: string
  url_facebook: string
  url_linkedin: string
  user: string
  registration_fee: number
  created_at: Date
}

interface RequestSelectiveProcess {
  process_alias: string
}

interface SelectiveProcessContextData {
  selectiveProcess: SelectiveProcess
  seachSelectiveProcess({
    process_alias,
  }: RequestSelectiveProcess): Promise<void>
}

interface SelectiveProcessState {
  selectiveProcess: SelectiveProcess
}

interface SelectiveProcessProviderProps {
  children: ReactNode
}

const SelectiveProcessContext = createContext<SelectiveProcessContextData>(
  {} as SelectiveProcessContextData,
)

function SelectiveProcessProvider({ children }: SelectiveProcessProviderProps) {
  const [dataSelectiveProcess, setDataSelectiveProcess] =
    useState<SelectiveProcessState>(() => {
      const selectiveProcess = sessionStorage.getItem(
        '@SelectiveProcess:selectiveProcess',
      )

      if (selectiveProcess) {
        return { selectiveProcess: JSON.parse(selectiveProcess) }
      }

      return {} as SelectiveProcessState
    })

  const seachSelectiveProcess = useCallback(
    async ({ process_alias }: RequestSelectiveProcess) => {
      const { data } = await api.get<SelectiveProcess>(
        `selectives-process/alias/${process_alias}`,
      )
      sessionStorage.setItem(
        '@SelectiveProcess:selectiveProcess',
        JSON.stringify(data),
      )

      setDataSelectiveProcess({
        selectiveProcess: data,
      })
    },
    [],
  )

  return (
    <SelectiveProcessContext.Provider
      value={{
        selectiveProcess: dataSelectiveProcess.selectiveProcess,
        seachSelectiveProcess,
      }}
    >
      {children}
    </SelectiveProcessContext.Provider>
  )
}

function useSelectiveProcess(): SelectiveProcessContextData {
  const context = useContext(SelectiveProcessContext)

  if (!context) {
    throw new Error(
      'useSelectiveProcess must be used within a SelectiveProcessProvider',
    )
  }

  return context
}

export { SelectiveProcessProvider, useSelectiveProcess }
