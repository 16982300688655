import {
  Flex,
  Heading,
  Button,
  Icon,
  Divider,
  Box,
  Avatar,
  Input as ChakraInput,
  useToast,
  SkeletonCircle,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import {
  ArrowRight,
  ClipboardText,
  CreditCard,
  User,
} from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChangeEvent, useState } from 'react'

import api from '../services/api'
import { useSelectiveProcess } from '../hook/selectiveProcess'

interface ResponseRegisterAvatarData {
  avatar: string
}

export function RegisterAvatar() {
  const { candidate_id } = useParams()

  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const toast = useToast()
  const navigate = useNavigate()

  const { selectiveProcess } = useSelectiveProcess()

  async function handleRegisterAvatar(e: ChangeEvent<HTMLInputElement>) {
    setLoading(true)

    try {
      if (e.target.files) {
        const data = new FormData()

        data.append('avatar', e.target.files[0])
        const response = await api.patch<ResponseRegisterAvatarData>(
          `candidates/avatar/${candidate_id}`,
          data,
        )

        setAvatarUrl(response.data.avatar)
        setLoading(false)

        toast({
          title: 'Foto enviada com sucesso!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }
    } catch (err) {
      setLoading(false)
      toast({
        title: 'Error ao fazer o upload da foto!',
        description:
          'Certifique-se de que a foto selecionada tenha tamanho inferior a 2,5 MB.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  async function NextPage() {
    navigate(
      `/${selectiveProcess.process_alias}/register/complementary-data/${candidate_id}`,
    )
  }

  if (!selectiveProcess) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          flexDirection="column"
          px="4"
        >
          <Flex
            maxW={800}
            w="100%"
            align="center"
            justify="center"
            flexDirection="column"
            py="6"
            px="8"
            borderRadius={20}
          >
            <Skeleton height={500} width="100%" />
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      flexDirection="column"
      px="4"
      minH="90vh"
    >
      <Heading
        as="h2"
        color={
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }
        textAlign="center"
        my="10"
      >
        {selectiveProcess.process_name}
      </Heading>

      <Flex
        maxW={800}
        w="100%"
        align="center"
        justify="center"
        flexDirection="column"
        py="6"
        px="8"
        borderRadius={20}
        border={`3px solid ${
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }`}
      >
        <Flex
          w="100%"
          maxW={['100%', 600]}
          justify="center"
          align="center"
          gap="2"
          my="6"
        >
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon as={User} fontSize="40px" color="#FFF" weight="fill" />
          </Flex>

          <Divider flex="1" borderBottomWidth="4px" />
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            border="2px solid #787878"
            borderRadius="full"
          >
            <Icon
              as={ClipboardText}
              fontSize="40px"
              color="#787878"
              weight="fill"
            />
          </Flex>

          <Divider flex="1" borderBottomWidth="4px" />

          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            border="2px solid #787878"
            borderRadius="full"
          >
            <Icon
              as={CreditCard}
              fontSize="40px"
              color="#787878"
              weight="fill"
            />
          </Flex>
        </Flex>

        <Heading
          as="h3"
          color={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          textAlign="center"
          my="6"
        >
          Selecione uma foto:
        </Heading>

        <Box w="100%" maxW="64" mb="8">
          <Box position="relative" cursor="pointer">
            <Box
              height="100%"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {loading ? (
                <SkeletonCircle size="100px" />
              ) : avatarUrl ? (
                <Avatar size="2xl" src={avatarUrl} />
              ) : (
                <Avatar size="2xl" bg="gray.700" />
              )}

              <ChakraInput
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept="image/jpeg, image/jpg"
                name="avatar"
                id="avatar"
                cursor="pointer"
                onChange={handleRegisterAvatar}
              />
            </Box>
          </Box>
        </Box>

        <Text mb="8">
          <strong>Orientações para enviar uma foto:</strong>
          <br />
          1. Tire a foto em um local bem iluminado, evitando sombras ou luz
          excessiva.
          <br />
          <strong>
            2. Não serão aceitas fotos com uso de adereços (óculos de sol,
            máscaras, gorros, turbantes, jóias que cubram o rosto, vendas etc.)
            que impossibilitem a identificação do candidato.
          </strong>
          <br />
          3. Certifique-se de que a foto esteja colorida e com um bom contraste
          entre você e o fundo. O fundo deve ser branco, e use uma blusa de cor
          escura.
          <br />
          4. Garanta que o rosto ocupe cerca de 2/3 da foto e esteja claramente
          visível, sem que a testa e as sobrancelhas estejam cobertas por
          cabelos ou acessórios, como bonés, toucas ou óculos. Os olhos devem
          estar visíveis. Mantenha a cabeça reta e olhe diretamente para a
          câmera.
          <br />
          5.Em formato JPEG e ter um tamanho máximo de 2,5 Mb.
        </Text>

        {avatarUrl && (
          <Flex
            as={Button}
            type="button"
            onClick={NextPage}
            colorScheme="teal"
            variant="outline"
            spinnerPlacement="start"
            w="100%"
            maxW={250}
            h={50}
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            color="#FFF"
            border="2px solid transparent"
            _hover={{
              borderColor:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
              bgColor: 'transparent',
              color:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
            }}
            fontWeight="bold"
            fontSize="20"
            borderRadius="12"
            justify="center"
            align="center"
            gap="2"
            my="8"
          >
            Próximo
            <ArrowRight size={16} />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
