import { Flex, Box, Skeleton } from '@chakra-ui/react'

import { useCountDown } from '../hook/countDown'
import { useSelectiveProcess } from '../hook/selectiveProcess'

import { DateTimeDisplay } from './DateTimeDisplay'

interface CountDownProps {
  dateEnd: string
}

export function CountDown({ dateEnd }: CountDownProps) {
  const { selectiveProcess } = useSelectiveProcess()
  const { countDown, startCountDownDate } = useCountDown()

  const targetDate = new Date(dateEnd).getTime()

  startCountDownDate(targetDate)

  if (
    countDown.days < 0 ||
    countDown.hours < 0 ||
    countDown.minutes < 0 ||
    countDown.seconds < 0
  ) {
    return <Skeleton height="200px" width="100%" />
  }

  return (
    <Flex align="center" justify="center" gap="3">
      <DateTimeDisplay
        isDanger={countDown.days < 3}
        type="Dias"
        value={countDown.days}
      />
      <Box
        fontSize={['3xl', '3xl', '3xl', '7xl']}
        lineHeight="none"
        color={
          selectiveProcess.color_header === '#FFF'
            ? '#002147'
            : selectiveProcess.color_header
        }
      >
        :
      </Box>
      <DateTimeDisplay
        isDanger={countDown.days === 0 && countDown.hours < 24}
        type="Horas"
        value={countDown.hours}
      />
      <Box
        fontSize={['3xl', '3xl', '3xl', '7xl']}
        lineHeight="none"
        color={
          selectiveProcess.color_header === '#FFF'
            ? '#002147'
            : selectiveProcess.color_header
        }
      >
        :
      </Box>
      <DateTimeDisplay
        isDanger={countDown.days === 0 && countDown.minutes < 60}
        type="Minutos"
        value={countDown.minutes}
      />
      <Box
        fontSize={['3xl', '3xl', '3xl', '7xl']}
        lineHeight="none"
        color={
          selectiveProcess.color_header === '#FFF'
            ? '#002147'
            : selectiveProcess.color_header
        }
      >
        :
      </Box>
      <DateTimeDisplay
        isDanger={countDown.days === 0 && countDown.minutes < 60}
        type="Segundos"
        value={countDown.seconds}
      />
    </Flex>
  )
}
