import { Flex, Skeleton, SkeletonText, Stack, Text } from '@chakra-ui/react'
import { SocialButton } from './SocialButton'
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
} from '@phosphor-icons/react'

import { useSelectiveProcess } from '../hook/selectiveProcess'

export function Footer() {
  const { selectiveProcess } = useSelectiveProcess()

  if (!selectiveProcess) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          mt={['8', '14']}
          bgColor="#cecece"
        >
          <Flex
            maxW={1250}
            w="100%"
            justify="space-between"
            align="center"
            p="4"
            gap="4"
            flexDirection={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
          >
            <SkeletonText noOfLines={1} spacing="4" skeletonHeight="2" />

            <Stack spacing="4" direction="row">
              <Skeleton height="50px" width="50px" />

              <Skeleton height="50px" width="50px" />
            </Stack>
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      as="footer"
      w="100%"
      justify="center"
      align="center"
      mt={['8', '14']}
      bgColor={selectiveProcess.color_header}
    >
      <Flex
        maxW={1250}
        w="100%"
        justify="space-between"
        align="center"
        p="4"
        gap="4"
        flexDirection={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
      >
        <Text
          color={
            selectiveProcess.color_header === '#FFF'
              ? selectiveProcess.color_base
              : '#FFF'
          }
          textAlign={['center', 'left']}
        >
          Copyright © 2023 COVEST. Todos os direitos reservados.
        </Text>

        <Stack spacing="4" direction="row">
          {selectiveProcess.url_instagram && (
            <SocialButton
              href={selectiveProcess.url_instagram}
              icon={InstagramLogo}
              title="Instagram"
              color={
                selectiveProcess.color_header === '#FFF'
                  ? selectiveProcess.color_base
                  : '#FFF'
              }
              bgColor={selectiveProcess.color_header}
            />
          )}

          {selectiveProcess.url_facebook && (
            <SocialButton
              href={selectiveProcess.url_facebook}
              icon={FacebookLogo}
              title="Instagram"
              color={
                selectiveProcess.color_header === '#FFF'
                  ? selectiveProcess.color_base
                  : '#FFF'
              }
              bgColor={selectiveProcess.color_header}
            />
          )}

          {selectiveProcess.url_linkedin && (
            <SocialButton
              href={selectiveProcess.url_linkedin}
              icon={LinkedinLogo}
              title="Instagram"
              color={
                selectiveProcess.color_header === '#FFF'
                  ? selectiveProcess.color_base
                  : '#FFF'
              }
              bgColor={selectiveProcess.color_header}
            />
          )}
        </Stack>
      </Flex>
    </Flex>
  )
}
