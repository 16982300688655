export default function getMimeTypeFromExtension(url: string): string {
  const urlParts = url.split('.')
  const extension = `.${urlParts[urlParts.length - 1]}`

  if (
    extension === '.png' ||
    extension === '.jpg' ||
    extension === '.jpeg' ||
    extension === '.gif'
  ) {
    return 'image'
  } else if (extension === '.pdf') {
    return 'pdf'
  } else {
    return 'document'
  }
}
