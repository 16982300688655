import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Flex,
  Heading,
  Button,
  Icon,
  Divider,
  useToast,
  Box,
  Skeleton,
} from '@chakra-ui/react'
import {
  ArrowRight,
  ClipboardText,
  CreditCard,
  User,
} from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom'

import { Select } from '../components/Form/Select'
import { Input } from '../components/Form/Input'
import api from '../services/api'
import { useSelectiveProcess } from '../hook/selectiveProcess'

const registerComplementryFormSchema = z.object({
  questions: z
    .object({
      question: z.string().min(3, { message: 'O campo é obrigatório.' }),
      answer: z.string().min(3, { message: 'O campo é obrigatório.' }),
    })
    .array(),
})

type RegisterComplementryFormData = z.infer<
  typeof registerComplementryFormSchema
>

export default function RegisterComplementry() {
  const { candidate_id } = useParams()

  const questionsDataForm = [
    {
      id: 1,
      question: 'Qual gênero você se identifica?',
      answers: [
        { value: 'Masculino', label: 'Masculino' },
        { value: 'Feminino', label: 'Feminino' },
        { value: 'Não-binário', label: 'Não-binário' },
        { value: 'Outro', label: 'Outro' },
        { value: 'Prefiro não dizer', label: 'Prefiro não dizer' },
      ],
    },
    {
      id: 2,
      question: 'Como você se considera?',
      answers: [
        { value: 'Branco(a)', label: 'Branco(a)' },
        { value: 'Pardo(a)', label: 'Pardo(a)' },
        { value: 'Preto(a)', label: 'Preto(a)' },
        { value: 'Amarelo(a)', label: 'Amarelo(a)' },
        { value: 'Indígena', label: 'Indígena' },
      ],
    },
    {
      id: 3,
      question: 'Você trabalha ou já trabalhou?',
      answers: [
        { value: 'Sim', label: 'Sim' },
        { value: 'Não', label: 'Não' },
      ],
    },
    {
      id: 4,
      question:
        'Qual principal motivo faria você voltar a estudar ou continuar estudando?',
      answers: [
        {
          value: 'Conseguir um emprego',
          label: 'Conseguir um emprego',
        },
        {
          value: 'Progredir no emprego atual',
          label: 'Progredir no emprego atual',
        },
        {
          value: 'Conseguir um emprego melhor',
          label: 'Conseguir um emprego melhor',
        },
        {
          value: 'Adquirir mais conhecimento, ficar atualizado',
          label: 'Adquirir mais conhecimento, ficar atualizado',
        },
        {
          value: 'Atender à expectativa de meus familiares sobre meus estudos',
          label: 'Atender à expectativa de meus familiares sobre meus estudos',
        },
      ],
    },
    {
      id: 5,
      question:
        'Você considera que seus conhecimentos adquiridos no Ensino Médio foram adequados ao que o mercado de trabalho solicita?',
      answers: [
        {
          value: 'Sim',
          label: 'Sim',
        },
        {
          value: 'Não',
          label: 'Não',
        },
      ],
    },
    {
      id: 6,
      question:
        'Você considera que seus conhecimentos adquiridos no Ensino Médio tiveram relação com a profissão que você escolheu / que você quer exercer?',
      answers: [
        {
          value: 'Sim',
          label: 'Sim',
        },
        {
          value: 'Não',
          label: 'Não',
        },
      ],
    },
    {
      id: 7,
      question:
        'Você considera que seus conhecimentos adquiridos no Ensino Médio foram bem desenvolvidos, com aulas práticas, laboratórios, etc.?',
      answers: [
        {
          value: 'Sim',
          label: 'Sim',
        },
        {
          value: 'Não',
          label: 'Não',
        },
      ],
    },
    {
      id: 8,
      question:
        'Você considera que seus conhecimentos adquiridos no Ensino Médio proporcionaram cultura e conhecimento?',
      answers: [
        {
          value: 'Sim',
          label: 'Sim',
        },
        {
          value: 'Não',
          label: 'Não',
        },
      ],
    },
    {
      id: 9,
      question:
        'Em que turno você cursou ou está cursando o ensino médio (2º grau)?',
      answers: [
        {
          value: 'Somente no turno diurno',
          label: 'Somente no turno diurno',
        },
        {
          value: 'Maior parte no turno diurno',
          label: 'Maior parte no turno diurno',
        },
        {
          value: 'Somente no turno noturno',
          label: 'Somente no turno noturno',
        },
        {
          value: 'Maior parte no turno noturno',
          label: 'Maior parte no turno noturno',
        },
      ],
    },
    {
      id: 10,
      question:
        'Em que tipo de escola você cursou ou está cursando o ensino médio (2º grau)?',
      answers: [
        {
          value: 'Somente em escola pública',
          label: 'Somente em escola pública',
        },
        {
          value: 'Maior parte em escola pública',
          label: 'Maior parte em escola pública',
        },
        {
          value: 'Maior parte em escola particular',
          label: 'Maior parte em escola particular',
        },
        {
          value: 'Somente em escola indígena',
          label: 'Somente em escola indígena',
        },
        {
          value: 'Maior parte em escola não-indígena',
          label: 'Maior parte em escola não-indígena',
        },
      ],
    },
    {
      id: 11,
      question:
        'Em que modalidade de ensino você concluiu ou vai concluir o ensino médio (2º grau)?',
      answers: [
        {
          value: 'Ensino regular',
          label: 'Ensino regular',
        },
        {
          value: 'Educação para jovens e adultos EJA (antigo supletivo)',
          label: 'Educação para jovens e adultos EJA (antigo supletivo)',
        },
        {
          value: 'Ensino técnico/ensino profissional.',
          label: 'Ensino técnico/ensino profissional.',
        },
      ],
    },
  ]

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<RegisterComplementryFormData>({
    resolver: zodResolver(registerComplementryFormSchema),
  })

  const toast = useToast()
  const navigate = useNavigate()

  const { selectiveProcess } = useSelectiveProcess()

  async function handleRegisterComplementry({
    questions,
  }: RegisterComplementryFormData) {
    try {
      await api.post('candidates/answes-survey', {
        candidate_id,
        questions,
      })

      toast({
        title: 'Cadastro efetuado com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      navigate(
        `/${selectiveProcess.process_alias}/register/payment/${candidate_id}`,
      )
    } catch (err) {
      toast({
        title: 'Error ao efetuar o cadastro!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  if (!selectiveProcess) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          flexDirection="column"
          px="4"
        >
          <Flex
            maxW={800}
            w="100%"
            align="center"
            justify="center"
            flexDirection="column"
            py="6"
            px="8"
            borderRadius={20}
          >
            <Skeleton height={500} width="100%" />
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      flexDirection="column"
      px="4"
    >
      <Heading
        as="h2"
        color={
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }
        textAlign="center"
        my="10"
      >
        {selectiveProcess.process_name}
      </Heading>

      <Flex
        as="form"
        onSubmit={handleSubmit(handleRegisterComplementry)}
        maxW={800}
        w="100%"
        align="center"
        justify="center"
        flexDirection="column"
        py="6"
        px="8"
        borderRadius={20}
        border={`3px solid ${
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }`}
      >
        <Flex
          w="100%"
          maxW={['100%', 600]}
          justify="center"
          align="center"
          gap="2"
          my="6"
        >
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon as={User} fontSize="40px" color="#FFF" weight="fill" />
          </Flex>

          <Divider
            flex="1"
            borderBottomWidth="4px"
            borderColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
          />
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon
              as={ClipboardText}
              fontSize="40px"
              color="#FFF"
              weight="fill"
            />
          </Flex>

          <Divider flex="1" borderBottomWidth="4px" />

          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            border="2px solid #787878"
            borderRadius="full"
          >
            <Icon
              as={CreditCard}
              fontSize="40px"
              color="#787878"
              weight="fill"
            />
          </Flex>
        </Flex>

        <Heading
          as="h3"
          color={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          textAlign="center"
          my="6"
        >
          Dados Complementares
        </Heading>

        <Flex flexDirection="column" width="100%" gap="6">
          {questionsDataForm.map((question, i) => (
            <Box key={question.id}>
              <Input
                type="hidden"
                {...register(`questions.${i}.question`)}
                value={question.question}
              />
              <Select
                label={`${question.id}. ${question.question}`}
                placeholder="Selecione uma resposta"
                options={question.answers}
                {...register(`questions.${i}.answer`)}
                borderColor="#787878"
                bgColor="transparent"
                color={
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base
                }
                _hover={{
                  borderColor:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                }}
                _focus={{
                  borderColor:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                }}
                colorLabel={
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base
                }
                error={
                  errors.questions ? errors.questions[i]?.answer : undefined
                }
              />
            </Box>
          ))}
        </Flex>

        <Flex
          as={Button}
          type="submit"
          isLoading={isSubmitting}
          colorScheme="teal"
          variant="outline"
          spinnerPlacement="start"
          w="100%"
          maxW={250}
          h={50}
          bgColor={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          color="#FFF"
          border="2px solid transparent"
          _hover={{
            borderColor:
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base,
            bgColor: 'transparent',
            color:
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base,
          }}
          fontWeight="bold"
          fontSize="20"
          borderRadius="12"
          justify="center"
          align="center"
          gap="2"
          my="8"
        >
          Próximo
          <ArrowRight size={16} />
        </Flex>
      </Flex>
    </Flex>
  )
}
