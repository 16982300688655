import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { cpf as cpfValid } from 'cpf-cnpj-validator'
import {
  Flex,
  Heading,
  Button,
  Icon,
  Divider,
  useToast,
  Skeleton,
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import {
  ArrowRight,
  ClipboardText,
  CreditCard,
  User,
} from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'

import api from '../services/api'
import { useSelectiveProcess } from '../hook/selectiveProcess'

import { Input } from '../components/Form/Input'

const registerPersonalFormSchema = z
  .object({
    name: z
      .string()
      .min(10, { message: 'O campo nome completo é obrigatório.' })
      .toUpperCase(),
    cpf: z
      .string()
      .min(14, { message: 'O campo cpf é obrigatório.' })
      .refine(
        (value) => {
          // Remove caracteres não numéricos do CPF
          const cleanedCpf = value.replace(/\D/g, '')

          // Verifica se o CPF é válido utilizando o cpf-cnpj-validator
          return cpfValid.isValid(cleanedCpf)
        },
        {
          message: 'CPF inválido',
        },
      ),
    rg: z.string(),
    birth_date: z
      .string()
      .min(10, { message: 'O campo data de nascimento é obrigatório.' }),
    cellPhone: z
      .string()
      .min(14, { message: 'O campo celular é obrigatório.' }),
    phone: z.string(),
    email: z
      .string()
      .email({ message: 'O campo e-mail é obrigatório.' })
      .toLowerCase(),
    password: z
      .string()
      .min(8, { message: 'A senha precisa ter pelo menos 8 caracteres.' })
      .regex(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[$*&@#])(?!.*(.)\1)[A-Za-z\d$*&@#]{8,}$/,
        {
          message:
            'No mínimo 1 Letra Maiúscula, 1 Número no mínimo, 1 Símbolo ($*&@#) e não permitir sequência igual.',
        },
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não são iguais.',
    path: ['confirmPassword'],
  })

type RegisterPersonalFormData = z.infer<typeof registerPersonalFormSchema>

interface ResponseRegisterPersonalData {
  contactId: string
}

export function RegisterPersonal() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<RegisterPersonalFormData>({
    resolver: zodResolver(registerPersonalFormSchema),
  })

  const toast = useToast()
  const navigate = useNavigate()

  const { selectiveProcess } = useSelectiveProcess()

  async function handleRegisterPersonal(data: RegisterPersonalFormData) {
    try {
      const response = await api.post<ResponseRegisterPersonalData>(
        'candidates',
        {
          selective_process_id: Number(selectiveProcess.id),
          name: data.name,
          cpf: data.cpf.replace(/\D/g, ''),
          rg: data.rg.replaceAll('.', '').replaceAll('-', ''),
          email: data.email,
          birth_date: data.birth_date,
          cellPhone: data.cellPhone.replace(/\D/g, ''),
          phone: data.phone.replace(/\D/g, ''),
          password: data.password,
        },
      )

      toast({
        title: 'Cadastro efetuado com sucesso!',
        description: `Enviamos para o número ${data.cellPhone} uma mensagem no whatsapp com o código de validação`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      navigate(
        `/${selectiveProcess.process_alias}/register/validation-phone/${response.data.contactId}`,
      )
    } catch (err) {
      toast({
        title: 'Error ao efetuar o cadastro!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  if (!selectiveProcess) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          flexDirection="column"
          px="4"
        >
          <Flex
            maxW={800}
            w="100%"
            align="center"
            justify="center"
            flexDirection="column"
            py="6"
            px="8"
            borderRadius={20}
          >
            <Skeleton height={500} width="100%" />
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      flexDirection="column"
      px="4"
    >
      <Heading
        as="h2"
        color={
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }
        textAlign="center"
        my="10"
      >
        {selectiveProcess.process_name}
      </Heading>

      <Flex
        as="form"
        onSubmit={handleSubmit(handleRegisterPersonal)}
        maxW={800}
        w="100%"
        align="center"
        justify="center"
        flexDirection="column"
        py="6"
        px="8"
        borderRadius={20}
        border={`3px solid ${
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }`}
      >
        <Flex
          w="100%"
          maxW={['100%', 600]}
          justify="center"
          align="center"
          gap="2"
          my="6"
        >
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon as={User} fontSize="40px" color="#FFF" weight="fill" />
          </Flex>

          <Divider flex="1" borderBottomWidth="4px" />
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            border="2px solid #787878"
            borderRadius="full"
          >
            <Icon
              as={ClipboardText}
              fontSize="40px"
              color="#787878"
              weight="fill"
            />
          </Flex>

          <Divider flex="1" borderBottomWidth="4px" />

          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            border="2px solid #787878"
            borderRadius="full"
          >
            <Icon
              as={CreditCard}
              fontSize="40px"
              color="#787878"
              weight="fill"
            />
          </Flex>
        </Flex>

        <Heading
          as="h3"
          color={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          textAlign="center"
          my="6"
        >
          Dados Cadastrais
        </Heading>

        <Flex flexDirection="column" width="100%" gap="4">
          <Input
            label="Nome Completo:"
            type="text"
            placeholder="Digite seu nome completo"
            {...register('name')}
            borderColor="#787878"
            bgColor="transparent"
            color={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            _hover={{
              borderColor:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
            }}
            _focus={{
              borderColor:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
            }}
            colorLabel={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            error={errors.name}
          />

          <Flex flexDirection={['column', 'column', 'row']} gap="4">
            <Input
              as={InputMask}
              mask="999.999.999-99"
              label="CPF:"
              type="text"
              placeholder="Digite seu CPF"
              {...register('cpf')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.cpf}
            />
            <Input
              label="RG:"
              type="text"
              placeholder="Digite seu RG"
              {...register('rg')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.rg}
            />
          </Flex>

          <Flex flexDirection={['column', 'column', 'row']} gap="4">
            <Input
              label="Data de nascimento:"
              type="date"
              placeholder="Digite sua data de nascimento"
              {...register('birth_date')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.birth_date}
            />
            <Input
              label="E-mail:"
              type="email"
              placeholder="Digite seu e-mail"
              {...register('email')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.email}
            />
          </Flex>

          <Flex flexDirection={['column', 'column', 'row']} gap="4">
            <Input
              as={InputMask}
              mask="(99)99999-9999"
              label="Celular:"
              type="text"
              placeholder="Digite seu celular"
              {...register('cellPhone')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.cellPhone}
            />
            <Input
              as={InputMask}
              mask="(99)9999-9999"
              label="Telefone:"
              type="text"
              placeholder="Digite seu telefone"
              {...register('phone')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.phone}
            />
          </Flex>

          <Flex flexDirection={['column', 'column', 'row']} gap="4">
            <Input
              label="Senha:"
              type="password"
              placeholder="Digite sua senha"
              {...register('password')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.password}
            />
            <Input
              label="Confirmação senha:"
              type="password"
              placeholder="Confirme sua senha"
              {...register('confirmPassword')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.confirmPassword}
            />
          </Flex>
        </Flex>

        <Flex
          as={Button}
          type="submit"
          isLoading={isSubmitting}
          colorScheme="teal"
          variant="outline"
          spinnerPlacement="start"
          w="100%"
          maxW={250}
          h={50}
          bgColor={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          color="#FFF"
          border="2px solid transparent"
          _hover={{
            borderColor:
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base,
            bgColor: 'transparent',
            color:
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base,
          }}
          fontWeight="bold"
          fontSize="20"
          borderRadius="12"
          justify="center"
          align="center"
          gap="2"
          my="8"
        >
          Próximo
          <ArrowRight size={16} />
        </Flex>
      </Flex>
    </Flex>
  )
}
