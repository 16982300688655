import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Flex,
  Heading,
  Button,
  Icon,
  Divider,
  useToast,
  Skeleton,
} from '@chakra-ui/react'
import {
  ArrowRight,
  ClipboardText,
  CreditCard,
  User,
} from '@phosphor-icons/react'
import { Input } from '../components/Form/Input'
import api from '../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelectiveProcess } from '../hook/selectiveProcess'

const registerValidationPhoneFormSchema = z.object({
  code: z
    .string()
    .min(6, { message: 'O código precisa ter 6 número.' })
    .max(6, { message: 'O código precisa ter 6 número.' }),
})

type RegisterValidationPhoneFormData = z.infer<
  typeof registerValidationPhoneFormSchema
>

interface ResponseRegisterValidationPhoneData {
  message: string
}

export function RegisterValidationPhone() {
  const { candidate_id } = useParams()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<RegisterValidationPhoneFormData>({
    resolver: zodResolver(registerValidationPhoneFormSchema),
  })

  const toast = useToast()
  const navigate = useNavigate()

  const { selectiveProcess } = useSelectiveProcess()

  async function handleRegisterValidationPhone(
    data: RegisterValidationPhoneFormData,
  ) {
    try {
      await api.post<ResponseRegisterValidationPhoneData>(
        `candidates/validation/code/${candidate_id}`,
        {
          code: data.code,
        },
      )

      toast({
        title: 'Código validado com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      navigate(
        `/${selectiveProcess.process_alias}/register/avatar/${candidate_id}`,
      )
    } catch (err) {
      toast({
        title: 'Código informado está incorreto!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  if (!selectiveProcess) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          flexDirection="column"
          px="4"
        >
          <Flex
            maxW={800}
            w="100%"
            align="center"
            justify="center"
            flexDirection="column"
            py="6"
            px="8"
            borderRadius={20}
          >
            <Skeleton height={500} width="100%" />
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      flexDirection="column"
      px="4"
      minH="90vh"
    >
      <Heading
        as="h2"
        color={
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }
        textAlign="center"
        my="10"
      >
        {selectiveProcess.process_name}
      </Heading>

      <Flex
        as="form"
        onSubmit={handleSubmit(handleRegisterValidationPhone)}
        maxW={800}
        w="100%"
        align="center"
        justify="center"
        flexDirection="column"
        py="6"
        px="8"
        borderRadius={20}
        border={`3px solid ${
          selectiveProcess.color_base === '#FFF'
            ? '#002147'
            : selectiveProcess.color_base
        }`}
      >
        <Flex
          w="100%"
          maxW={['100%', 600]}
          justify="center"
          align="center"
          gap="2"
          my="6"
        >
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            borderRadius="full"
          >
            <Icon as={User} fontSize="40px" color="#FFF" weight="fill" />
          </Flex>

          <Divider flex="1" borderBottomWidth="4px" />
          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            border="2px solid #787878"
            borderRadius="full"
          >
            <Icon
              as={ClipboardText}
              fontSize="40px"
              color="#787878"
              weight="fill"
            />
          </Flex>

          <Divider flex="1" borderBottomWidth="4px" />

          <Flex
            w="60px"
            h="60px"
            justify="center"
            align="center"
            border="2px solid #787878"
            borderRadius="full"
          >
            <Icon
              as={CreditCard}
              fontSize="40px"
              color="#787878"
              weight="fill"
            />
          </Flex>
        </Flex>

        <Heading
          as="h3"
          color={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          textAlign="center"
          my="6"
        >
          Validação do número de celular
        </Heading>

        <Flex flexDirection="column" width="100%" gap="4">
          <Input
            label="Código:"
            type="text"
            placeholder="Digite o código"
            {...register('code')}
            borderColor="#787878"
            bgColor="transparent"
            color={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            _hover={{
              borderColor:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
            }}
            _focus={{
              borderColor:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
            }}
            colorLabel={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            error={errors.code}
          />
        </Flex>

        <Flex
          as={Button}
          type="submit"
          isLoading={isSubmitting}
          colorScheme="teal"
          variant="outline"
          spinnerPlacement="start"
          w="100%"
          maxW={250}
          h={50}
          bgColor={
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }
          color="#FFF"
          border="2px solid transparent"
          _hover={{
            borderColor:
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base,
            bgColor: 'transparent',
            color:
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base,
          }}
          fontWeight="bold"
          fontSize="20"
          borderRadius="12"
          justify="center"
          align="center"
          gap="2"
          my="8"
        >
          Próximo
          <ArrowRight size={16} />
        </Flex>
      </Flex>
    </Flex>
  )
}
