import { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Avatar,
  Button,
  Flex,
  Image,
  Link as ChakraLink,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  ListIcon,
  Input as ChakraInput,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  SkeletonCircle,
  Box,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import { CaretDown, FileImage, FilePdf, FileText } from '@phosphor-icons/react'
import dayjs from 'dayjs'

import { useAuth } from '../hook/auth'
import { useSelectiveProcess } from '../hook/selectiveProcess'
import api from '../services/api'
import dateIsSameOrAfter from '../utils/dateIsSameOrAfter'
import dateIsSameOrBefore from '../utils/dateIsSameOrBefore'
import getMimeTypeFromExtension from '../utils/getMimeTypeFromExtension'
import { Input } from '../components/Form/Input'

interface ResponseTimelineSelectiveProcess {
  id: number
  selective_processes_id: string
  description: string
  date_start: string
  date_end: string
  subscription_open: string
  url: string
}

interface ResponseDocumentSelectiveProcess {
  id: number
  selective_processes_id: string
  description: string
  url: string
}

interface ResponseRegisterAvatarData {
  avatar: string
}

interface Candidate {
  id: string
  name: string
  avatar: string
  status: string
  transaction_type: string
  registration_ticket: string
  summons_card: string
}

const updatePasswordFormSchema = z
  .object({
    old_password: z
      .string()
      .min(8, { message: 'A senha precisa ter pelo menos 8 caracteres.' }),
    password: z
      .string()
      .min(8, { message: 'A senha precisa ter pelo menos 8 caracteres.' })
      .regex(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[$*&@#])(?!.*(.)\1)[A-Za-z\d$*&@#]{8,}$/,
        {
          message:
            'No mínimo 1 Letra Maiúscula, 1 Número no mínimo, 1 Símbolo ($*&@#) e não permitir sequência igual.',
        },
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não são iguais.',
    path: ['confirmPassword'],
  })

type UpdatePasswordFormData = z.infer<typeof updatePasswordFormSchema>

export function Dashboard() {
  const [timelineSelectiveProcess, setTimelineSelectiveProcess] = useState<
    ResponseTimelineSelectiveProcess[]
  >([])
  const [infoRegistration, setInfoRegistration] =
    useState<ResponseTimelineSelectiveProcess>()
  const [documentsSelectiveProcess, setDocumentsSelectiveProcess] = useState<
    ResponseDocumentSelectiveProcess[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)
  const { candidate, signOutCandidate, updateDataCandidate } = useAuth()
  const { selectiveProcess } = useSelectiveProcess()
  const {
    isOpen: isOpenModalAvatar,
    onOpen: onOpenModalAvatar,
    onClose: onCloseModalAvatar,
  } = useDisclosure()
  const {
    isOpen: isOpenModalUpdatePassword,
    onOpen: onOpenModalUpdatePassword,
    onClose: onCloseModalUpdatePassword,
  } = useDisclosure()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<UpdatePasswordFormData>({
    resolver: zodResolver(updatePasswordFormSchema),
  })

  const toast = useToast()

  useEffect(() => {
    api
      .get<ResponseTimelineSelectiveProcess[]>(
        `selectives-process/timeline/${selectiveProcess.id}`,
      )
      .then((response) => {
        setTimelineSelectiveProcess(response.data)

        const timeline = response.data

        const indexTimeline = timeline.findIndex(
          (timeline) => timeline.description === 'Inscrições',
        )
        const info = timeline[indexTimeline]

        setInfoRegistration(info)
      })

    api
      .get<ResponseTimelineSelectiveProcess[]>(
        `selectives-process/documents/all/${selectiveProcess.id}`,
      )
      .then((response) => {
        setDocumentsSelectiveProcess(response.data)
      })
  }, [selectiveProcess.id])

  async function handleRegisterAvatar(e: ChangeEvent<HTMLInputElement>) {
    setLoading(true)

    const token = sessionStorage.getItem('@Candidate:token')

    try {
      if (e.target.files) {
        const data = new FormData()

        data.append('avatar', e.target.files[0])
        const response = await api.patch<ResponseRegisterAvatarData>(
          `candidates/avatar/${candidate.id}`,
          data,
        )

        sessionStorage.removeItem('@Candidate:candidate')

        const updateStorageCandidate: Candidate = {
          id: candidate.id,
          name: candidate.name,
          avatar: response.data.avatar,
          status: candidate.status,
          registration_ticket: candidate.registration_ticket,
          transaction_type: candidate.transaction_type,
          summons_card: candidate.summons_card,
        }

        sessionStorage.setItem(
          '@Candidate:candidate',
          JSON.stringify(updateStorageCandidate),
        )

        updateDataCandidate({
          token: String(token),
          candidate: updateStorageCandidate,
        })

        setLoading(false)

        toast({
          title: 'Foto enviada com sucesso!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }
    } catch (err) {
      setLoading(false)

      toast({
        title: 'Error ao fazer o upload da foto!',
        description:
          'Certifique-se de que a foto selecionada tenha tamanho inferior a 2,5 MB.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  async function handleUpdatePassword(data: UpdatePasswordFormData) {
    try {
      await api.patch('candidates/password/update', {
        old_password: data.old_password,
        password: data.password,
      })

      toast({
        title: 'Senha alterada com sucesso.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      onCloseModalUpdatePassword()
    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao tentar alterar sua senha.',
        description:
          'Por favor, verifique se a senha atual está correta e tente novamente.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <Flex
        as="header"
        w="100%"
        justify="center"
        align="center"
        h={[280, 280, 120]}
        position="fixed"
        bgColor={selectiveProcess.color_header}
      >
        <Flex
          maxW={1250}
          w="100%"
          justify="space-between"
          align="center"
          flexDirection={['column', 'column', 'row']}
          gap="8"
          px="6"
        >
          <Image
            src={selectiveProcess.url_logo.split('^')[0]}
            alt="Fatra"
            maxW={180}
            w="100%"
          />
          <Menu>
            <MenuButton
              as={Button}
              h="100%"
              p="2"
              color={
                selectiveProcess.color_header === '#FFF'
                  ? selectiveProcess.color_base
                  : '#FFF'
              }
              bgColor={selectiveProcess.color_header}
              _hover={{
                filter: 'brightness(0.9)',
              }}
              _focus={{
                filter: 'brightness(0.9)',
              }}
              _active={{
                filter: 'brightness(0.9)',
              }}
              rightIcon={<CaretDown size={22} />}
            >
              <Flex
                gap="4"
                flexDirection={['column', 'column', 'row']}
                justify="center"
                align="center"
              >
                <Avatar size="lg" src={candidate.avatar} />
                <Text
                  color={
                    selectiveProcess.color_header === '#FFF'
                      ? selectiveProcess.color_base
                      : '#FFF'
                  }
                >
                  Bem-Vindo,
                  <br />
                  <strong>{candidate.name.split(' ')[0]}</strong>
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={onOpenModalAvatar}
                color={
                  selectiveProcess.color_header === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base
                }
              >
                Alterar Foto
              </MenuItem>
              <MenuItem
                onClick={onOpenModalUpdatePassword}
                color={
                  selectiveProcess.color_header === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base
                }
              >
                Alterar Senha
              </MenuItem>
              <MenuItem
                color={
                  selectiveProcess.color_header === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base
                }
                onClick={signOutCandidate}
              >
                Sair
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex
        as="main"
        flexDirection="column"
        w="100%"
        minH="90vh"
        align="center"
        pt={[280, 280, 120]}
      >
        {!candidate.avatar && (
          <Flex
            maxW={800}
            w="100%"
            justify="center"
            flexDirection="column"
            mt="12"
          >
            <Alert status="error" borderRadius={10} fontWeight="bold">
              <AlertIcon />
              📸 Atualize sua foto: Faça o upload da sua foto para dar
              continuidade ao processo seletivo.
            </Alert>
          </Flex>
        )}

        <Flex
          maxW={800}
          w="100%"
          justify="center"
          flexDirection="column"
          mt="12"
          py="12"
          px="14"
          borderRadius={20}
          border={`3px solid ${
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }`}
          gap="6"
        >
          <Text fontSize="2xl">
            <strong>Número de inscrição:</strong> {candidate.summons_card}
          </Text>
          <Text fontSize="2xl">
            <strong>Nome Completo:</strong> {candidate.name}
          </Text>

          {candidate.transaction_type === 'ticket' &&
            candidate.status === 'R' && (
              <Text fontSize="2xl">
                <strong>Status inscrição:</strong> Pagamento Rejeitado
                {candidate.registration_ticket &&
                  infoRegistration &&
                  dateIsSameOrBefore(infoRegistration.date_end) && (
                    <>
                      {' '}
                      -{' '}
                      <ChakraLink
                        href={`/${selectiveProcess.process_alias}/register/payment/${candidate.id}`}
                        target="_blank"
                        rel="noopener"
                        color={
                          selectiveProcess.color_header === '#FFF'
                            ? '#002147'
                            : selectiveProcess.color_base
                        }
                      >
                        Confirmar Inscrição
                      </ChakraLink>
                    </>
                  )}
              </Text>
            )}

          {candidate.avatar !== null &&
            !candidate.transaction_type &&
            infoRegistration &&
            dateIsSameOrBefore(infoRegistration.date_end) && (
              <Text fontSize="2xl">
                <strong>Status inscrição:</strong> Aguardando Pagamento
                <>
                  {' '}
                  -{' '}
                  <ChakraLink
                    href={`/${selectiveProcess.process_alias}/register/payment/${candidate.id}`}
                    target="_blank"
                    rel="noopener"
                    color={
                      selectiveProcess.color_header === '#FFF'
                        ? '#002147'
                        : selectiveProcess.color_base
                    }
                  >
                    Confirmar Inscrição
                  </ChakraLink>
                </>
              </Text>
            )}

          {candidate.transaction_type === 'ticket' &&
            candidate.status === 'P' && (
              <Text fontSize="2xl">
                <strong>Status inscrição:</strong> Aguardando Pagamento
                {candidate.registration_ticket &&
                  infoRegistration &&
                  dateIsSameOrBefore(infoRegistration.date_end) && (
                    <>
                      {' '}
                      -{' '}
                      <ChakraLink
                        href={`/${selectiveProcess.process_alias}/register/payment/${candidate.id}`}
                        target="_blank"
                        rel="noopener"
                        color={
                          selectiveProcess.color_header === '#FFF'
                            ? '#002147'
                            : selectiveProcess.color_base
                        }
                      >
                        2º Via do boleto
                      </ChakraLink>
                    </>
                  )}
                <>
                  {' '}
                  -{' '}
                  <ChakraLink
                    href={`/${selectiveProcess.process_alias}/register/payment/${candidate.id}`}
                    target="_blank"
                    rel="noopener"
                    color={
                      selectiveProcess.color_header === '#FFF'
                        ? '#002147'
                        : selectiveProcess.color_base
                    }
                  >
                    2º Via do boleto
                  </ChakraLink>
                </>
              </Text>
            )}

          {candidate.status === 'A' && (
            <Text fontSize="2xl">
              <strong>Status inscrição:</strong> Inscrição Deferida
            </Text>
          )}

          {candidate.status === 'C' && (
            <Text fontSize="2xl">
              <strong>Status inscrição:</strong> Cancelada
            </Text>
          )}

          {candidate.summons_card && candidate.status === 'A' && (
            <Text fontSize="2xl">
              <strong>Cartão de Convocação:</strong>
              <>
                {' '}
                <ChakraLink
                  href={`https://engbox.com.br/clidocs/fatra/cartao-convocacao/${candidate.summons_card}.pdf`}
                  target="_blank"
                  rel="noopener"
                  color={
                    selectiveProcess.color_header === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base
                  }
                >
                  Imprimir
                </ChakraLink>
              </>
            </Text>
          )}
        </Flex>

        <Flex
          maxW={1200}
          w="100%"
          justify="space-between"
          p="4"
          my={['8', '12']}
        >
          <Tabs variant="enclosed" w="100%">
            <TabList>
              <Tab
                _selected={{
                  color: 'white',
                  bg:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                }}
              >
                Cronograma
              </Tab>
              <Tab
                _selected={{
                  color: 'white',
                  bg:
                    selectiveProcess.color_base === '#FFF'
                      ? '#002147'
                      : selectiveProcess.color_base,
                }}
              >
                Informações gerais
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <List spacing={3}>
                  {timelineSelectiveProcess.map((timeline) => (
                    <ListItem key={timeline.id}>
                      {dayjs(timeline.date_start).isSame(timeline.date_end)
                        ? `${timeline.description} - ${dayjs(
                            timeline.date_start,
                          ).format('DD/MM/YYYY')}`
                        : `${timeline.description} - ${dayjs(
                            timeline.date_start,
                          ).format('DD/MM/YYYY')} até ${dayjs(
                            timeline.date_end,
                          ).format('DD/MM/YYYY')}`}
                      {dateIsSameOrAfter(timeline.date_start) &&
                      dateIsSameOrBefore(timeline.date_end)
                        ? ' - Em andamento'
                        : dayjs(timeline.date_end).isBefore(new Date())
                        ? ' - Finalizado'
                        : ''}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>

              <TabPanel>
                <List spacing={3}>
                  {documentsSelectiveProcess.map((document) => (
                    <ListItem key={document.id}>
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'image' && (
                        <ListIcon
                          as={FileImage}
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'document' && (
                        <ListIcon
                          as={FileText}
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                        />
                      )}
                      {getMimeTypeFromExtension(document.url.split('^')[0]) ===
                        'pdf' && (
                        <ListIcon
                          as={FilePdf}
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                        />
                      )}

                      {document.url ? (
                        <ChakraLink
                          h="12"
                          px="3"
                          fontWeight="bold"
                          color={
                            selectiveProcess.color_base === '#FFF'
                              ? '#002147'
                              : selectiveProcess.color_base
                          }
                          href={document.url.split('^')[0]}
                          target="_blank"
                          _hover={{
                            filter: 'brightness(0.9)',
                          }}
                        >
                          {document.description}
                        </ChakraLink>
                      ) : (
                        document.description
                      )}
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>

      <Modal
        isOpen={isOpenModalUpdatePassword}
        onClose={onCloseModalUpdatePassword}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(handleUpdatePassword)}>
          <ModalHeader
            color={
              selectiveProcess.color_header === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
          >
            Alterar Senha
          </ModalHeader>
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="4"
            py="6"
          >
            <Input
              label="Senha Atual:"
              type="password"
              placeholder="Digite sua senha"
              {...register('old_password')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.old_password}
            />

            <Input
              label="Senha:"
              type="password"
              placeholder="Digite sua senha"
              {...register('password')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.password}
            />

            <Input
              label="Confirmação senha:"
              type="password"
              placeholder="Confirme sua senha"
              {...register('confirmPassword')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.confirmPassword}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              isLoading={isSubmitting}
              bgColor={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              color="#FFF"
              border="2px solid transparent"
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
                bgColor: 'transparent',
                color:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              mr={3}
            >
              Alterar
            </Button>
            <Button
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              border="2px solid transparent"
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
                bgColor: 'transparent',
                color:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              onClick={onCloseModalUpdatePassword}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenModalAvatar} onClose={onCloseModalAvatar} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            color={
              selectiveProcess.color_header === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
          >
            Alterar Foto
          </ModalHeader>
          <ModalCloseButton
            color={
              selectiveProcess.color_header === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
          />
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            py="6"
          >
            <Box w="100%" maxW="64">
              <Box position="relative" cursor="pointer">
                <Box
                  height="100%"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  {loading ? (
                    <SkeletonCircle
                      w={['200px', '200px', '300px']}
                      h={['200px', '200px', '300px']}
                    />
                  ) : candidate.avatar ? (
                    <Avatar
                      w={['200px', '200px', '300px']}
                      h={['200px', '200px', '300px']}
                      src={candidate.avatar}
                    />
                  ) : (
                    <Avatar
                      w={['200px', '200px', '300px']}
                      h={['200px', '200px', '300px']}
                      bg="gray.700"
                    />
                  )}
                  <ChakraInput
                    type="file"
                    height="100%"
                    width="100%"
                    position="absolute"
                    top="0"
                    left="0"
                    opacity="0"
                    aria-hidden="true"
                    accept="image/jpeg, image/jpg"
                    name="avatar"
                    id="avatar"
                    cursor="pointer"
                    onChange={handleRegisterAvatar}
                  />
                </Box>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Text>
              <strong>Orientações para enviar uma foto:</strong>
              <br />
              1. Tire a foto em um local bem iluminado, evitando sombras ou luz
              excessiva.
              <br />
              <strong>
                2. Não serão aceitas fotos com uso de adereços (óculos de sol,
                máscaras, gorros, turbantes, jóias que cubram o rosto, vendas
                etc.) que impossibilitem a identificação do candidato.
              </strong>
              <br />
              3. Certifique-se de que a foto esteja colorida e com um bom
              contraste entre você e o fundo. O fundo deve ser branco, e use uma
              blusa de cor escura.
              <br />
              4. Garanta que o rosto ocupe cerca de 2/3 da foto e esteja
              claramente visível, sem que a testa e as sobrancelhas estejam
              cobertas por cabelos ou acessórios, como bonés, toucas ou óculos.
              Os olhos devem estar visíveis. Mantenha a cabeça reta e olhe
              diretamente para a câmera.
              <br />
              5.Em formato JPEG e ter um tamanho máximo de 2,5 Mb.
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
