import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Flex,
  Image,
  Link as ChakraLink,
  Heading,
  Button,
  useToast,
  Skeleton,
} from '@chakra-ui/react'
import { ArrowLeft, SignIn as SignInIcon } from '@phosphor-icons/react'
import { Input } from '../components/Form/Input'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '../hook/auth'
import { useSelectiveProcess } from '../hook/selectiveProcess'

const signInFormSchema = z.object({
  email: z.string().email({ message: 'Digite um e-mail' }),
  password: z.string().min(8, { message: 'Digite uma senha' }),
})

type SignInFormData = z.infer<typeof signInFormSchema>

export function SignIn() {
  const { selectiveProcess } = useSelectiveProcess()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<SignInFormData>({
    resolver: zodResolver(signInFormSchema),
  })

  const { signInCandidate } = useAuth()

  const toast = useToast()
  const navigate = useNavigate()

  async function handleSignIn(data: SignInFormData) {
    try {
      await signInCandidate({
        email: data.email,
        password: data.password,
        selective_process_id: selectiveProcess.id,
      })

      navigate(`/${selectiveProcess.process_alias}/dashboard`)
    } catch (err) {
      toast({
        title: 'Desculpe, as informações de login fornecidas são inválidas!',
        description:
          'Por favor, verifique seu e-mail e senha e tente novamente.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  if (!selectiveProcess) {
    return (
      <>
        <Flex
          w="100%"
          justify="center"
          align="center"
          flexDirection="column"
          px="4"
        >
          <Flex
            maxW={800}
            w="100%"
            align="center"
            justify="center"
            flexDirection="column"
            py="6"
            px="8"
            borderRadius={20}
          >
            <Skeleton height={500} width="100%" />
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex w="100%" justify="center" align="center">
        <Flex
          as="form"
          onSubmit={handleSubmit(handleSignIn)}
          maxW={550}
          w="100%"
          align="center"
          justify="center"
          flexDirection="column"
          py="6"
          px="8"
          mt="14"
          mx="4"
          borderRadius={20}
          border={`3px solid ${
            selectiveProcess.color_base === '#FFF'
              ? '#002147'
              : selectiveProcess.color_base
          }`}
        >
          <Image
            src={selectiveProcess.url_logo.split('^')[0]}
            alt="Fatra"
            maxW={180}
            w="100%"
            bgColor={selectiveProcess.color_base}
            borderRadius="12"
          />
          <Heading
            as="h2"
            color={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            my="8"
          >
            Portal do Candidato
          </Heading>
          <Flex flexDirection="column" width="100%" gap="4">
            <Input
              label="E-mail:"
              type="email"
              placeholder="Digite seu e-mail"
              {...register('email')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.email}
            />
            <Input
              label="Senha:"
              type="password"
              placeholder="Digite sua senha"
              {...register('password')}
              borderColor="#787878"
              bgColor="transparent"
              color={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              _hover={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              _focus={{
                borderColor:
                  selectiveProcess.color_base === '#FFF'
                    ? '#002147'
                    : selectiveProcess.color_base,
              }}
              colorLabel={
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base
              }
              error={errors.password}
            />
          </Flex>
          <ChakraLink
            as={NavLink}
            w="100%"
            to={`/${selectiveProcess.process_alias}/forgot`}
            fontWeight="bold"
            color={selectiveProcess.color_base}
            _hover={{
              filter: 'brightness(0.8)',
            }}
          >
            Esqueceu sua senha?
          </ChakraLink>

          <Flex
            as={Button}
            type="submit"
            isLoading={isSubmitting}
            colorScheme="teal"
            variant="outline"
            spinnerPlacement="start"
            w="100%"
            h={55}
            bgColor={
              selectiveProcess.color_base === '#FFF'
                ? '#002147'
                : selectiveProcess.color_base
            }
            color="#FFF"
            border="2px solid transparent"
            _hover={{
              borderColor:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
              bgColor: 'transparent',
              color:
                selectiveProcess.color_base === '#FFF'
                  ? '#002147'
                  : selectiveProcess.color_base,
            }}
            fontWeight="bold"
            fontSize="25"
            borderRadius="12"
            justify="center"
            align="center"
            gap="2"
            my="8"
          >
            <SignInIcon size={22} />
            Entrar
          </Flex>

          <ChakraLink
            as={NavLink}
            to={`/${selectiveProcess.process_alias}`}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            borderRadius="12"
            gap="2"
            h="12"
            px="3"
            mt="2"
            fontWeight="bold"
            fontSize="18"
            color={selectiveProcess.color_base}
            _hover={{
              filter: 'brightness(0.8)',
            }}
          >
            <ArrowLeft size={20} />
            Volta
          </ChakraLink>
        </Flex>
      </Flex>
    </>
  )
}
