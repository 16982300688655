import { Route, Routes as RoutesDOM } from 'react-router-dom'

import { SignIn } from '../pages/Signin'
import RouteCandidate from './RouteCandidate'
import RouteSelectiveProcess from './RouteSelectiveProcess'
import { Dashboard } from '../pages/Dashboard'
import { ResetPassword } from '../pages/ResetPassword'
import { ForgotPassword } from '../pages/ForgotPassword'
import Error404 from '../pages/Error404'
import { Home } from '../pages/Home'
import { RegisterPersonal } from '../pages/RegisterPersonal'
import { RegisterValidationPhone } from '../pages/RegisterValidationPhone'
import { RegisterAvatar } from '../pages/RegisterAvatar'
import RegisterComplementry from '../pages/RegisterComplementry'
import RegisterPayment from '../pages/RegisterPayment'

export const Routes = () => {
  return (
    <RoutesDOM>
      <Route path="*" element={<Error404 />} />
      <Route path="/:process_alias" element={<RouteSelectiveProcess />}>
        <Route path="/:process_alias" element={<Home />} />
        <Route
          path="/:process_alias/register/personal-data"
          element={<RegisterPersonal />}
        />
        <Route
          path="/:process_alias/register/validation-phone/:candidate_id"
          element={<RegisterValidationPhone />}
        />
        <Route
          path="/:process_alias/register/avatar/:candidate_id"
          element={<RegisterAvatar />}
        />
        <Route
          path="/:process_alias/register/complementary-data/:candidate_id"
          element={<RegisterComplementry />}
        />
        <Route
          path="/:process_alias/register/payment/:candidate_id"
          element={<RegisterPayment />}
        />
        <Route path="/:process_alias/signin" element={<SignIn />} />
        <Route path="/:process_alias/forgot" element={<ForgotPassword />} />
        <Route
          path="/:process_alias/reset/:token"
          element={<ResetPassword />}
        />
        <Route path="/:process_alias/dashboard" element={<RouteCandidate />}>
          <Route path="/:process_alias/dashboard" element={<Dashboard />} />
        </Route>
      </Route>
    </RoutesDOM>
  )
}
