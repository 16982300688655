import { Icon, Link, LinkProps } from '@chakra-ui/react'
import { ElementType } from 'react'

interface SocialButtonProps extends LinkProps {
  icon: ElementType
  href: string
}

export function SocialButton({ href, icon, ...rest }: SocialButtonProps) {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="8"
      w="50px"
      h="50px"
      _hover={{
        textDecoration: 'none',
        filter: 'brightness(0.8)',
      }}
      {...rest}
    >
      <Icon as={icon} fontSize="30" />
    </Link>
  )
}
