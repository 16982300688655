import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
  forwardRef,
} from '@chakra-ui/react'
import { ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface SelectProps extends ChakraSelectProps {
  name: string
  label?: string
  placeholder?: string
  colorLabel?: string
  error?: FieldError | undefined
  options: {
    value: string | number
    label: string | number
  }[]
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  {
    name,
    label,
    placeholder,
    colorLabel = '#002147',
    error = null,
    options,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} color={colorLabel}>
          {label}
        </FormLabel>
      )}

      <ChakraSelect
        id={name}
        name={name}
        placeholder={placeholder}
        variant="filled"
        borderRadius="12"
        size="lg"
        ref={ref}
        {...rest}
      >
        {options.map((option) => (
          <option
            style={{
              color: colorLabel,
            }}
            value={option.value}
            key={option.value}
          >
            {option.label}
          </option>
        ))}
      </ChakraSelect>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Select = forwardRef(SelectBase)
