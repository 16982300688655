import { ReactNode } from 'react'
import { AuthProvider } from './auth'
import { SelectiveProcessProvider } from './selectiveProcess'
import { CountDownProvider } from './countDown'

interface AppProviderProps {
  children: ReactNode
}

function AppProvider({ children }: AppProviderProps) {
  return (
    <SelectiveProcessProvider>
      <CountDownProvider>
        <AuthProvider>{children}</AuthProvider>
      </CountDownProvider>
    </SelectiveProcessProvider>
  )
}

export default AppProvider
