import { Icon, Link, LinkProps } from '@chakra-ui/react'
import { WhatsappLogo } from '@phosphor-icons/react'

interface WhatsAppButtonProps extends LinkProps {
  href: string
}

export function WhatsAppButton({ href, ...rest }: WhatsAppButtonProps) {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      color="white"
      bg="#34af23"
      w={['55px', '50px']}
      h={['55px', '50px']}
      position="fixed"
      bottom="4"
      right="4"
      _hover={{
        filter: 'brightness(0.8)',
      }}
      zIndex="9"
      title="WhatsApp"
      {...rest}
    >
      <Icon as={WhatsappLogo} fontSize="30" />
    </Link>
  )
}
