export default function dateIsSameOrBefore(date_end: string): boolean {
  // Converter a data do banco (string) em um objeto Date
  const date_compare: Date = new Date(date_end)

  // Obter a data atual
  const currentDate: Date = new Date()

  // Comparar as datas
  if (currentDate <= date_compare) {
    return true
  } else {
    return false
  }
}
